exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-news-js": () => import("./../../../src/pages/news.js" /* webpackChunkName: "component---src-pages-news-js" */),
  "component---src-pages-people-js": () => import("./../../../src/pages/people.js" /* webpackChunkName: "component---src-pages-people-js" */),
  "component---src-pages-publications-js": () => import("./../../../src/pages/publications.js" /* webpackChunkName: "component---src-pages-publications-js" */),
  "component---src-pages-research-cryst-js": () => import("./../../../src/pages/research/cryst.js" /* webpackChunkName: "component---src-pages-research-cryst-js" */),
  "component---src-pages-research-design-js": () => import("./../../../src/pages/research/design.js" /* webpackChunkName: "component---src-pages-research-design-js" */),
  "component---src-pages-research-exper-js": () => import("./../../../src/pages/research/exper.js" /* webpackChunkName: "component---src-pages-research-exper-js" */)
}

